<template>
  <v-theme-provider :dark="dark">
    <div>
      <h3 class="mb-3">¿ Ya tienes una cuenta ?</h3>
      <base-info-card
        title="INGRESA "
        pace="6"
      >
        <v-divider class="mt-6"></v-divider>
        <p class="mt-3">Si ya te encuentras registrado, puedes ingresar a tu cuenta haciendo click en el siguiente botón</p>
        <v-btn
          class="font-weight-bold"
          color="white"
          :href="path_to_certificaciones"
          light
          min-width="168"
          target="_blank"
          x-large
        >
          Ingresar a mi cuenta
          <v-icon right>
            mdi-open-in-new
          </v-icon>
        </v-btn>
        <p class="mt-3">Donde puedes presentar tu solicitud de Certificación o Recertificación</p>
      </base-info-card>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfoCuenta',
    props: { dark: Boolean },
    data: () => ({
      path_to_certificaciones:process.env.VUE_APP_CERTIFICACIONES_URL + '/login',
    }),
  }
</script>
