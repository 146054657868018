<template>
  <div>
    <v-row>
      <v-col>
        <base-info-card
          :title="title"
          :subtitle="subtitle"
          space="4"
          color="primary"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <ul>
          <li><a href="/consejo">El Consejo</a></li>
          <li class="mt-3"><a href="/residencias">Residencias</a></li>
        </ul>

      </v-col>

      <v-col md="6">
        <ul>
          <li class="mt-3"><a href="https://conacem.org.mx/" target="_blank">CONACEM</a></li>
        </ul>
      </v-col>
    </v-row>

    <v-divider class="mt-5"></v-divider>

    <v-row>
      <v-col>
        <base-info-card
          :title="title2"
          :subtitle="subtitle"
          space="4"
          color="primary"
          class="mt-5"
        />

        <ul>
          <li class="mt-3"><a href="/medicos">Consejo Mexicano de Patología Clínica y Medicina de Laboratorio A.C.</a></li>
          <li class="mt-3"><a href="https://www.cap.org/" target="_blank">College of American Pathologists</a></li>
        </ul>
        <v-divider class="mt-8"></v-divider>
      </v-col>
    </v-row>


    <!--<base-btn
      class="mt-16"
      :color="!theme.isDark ? 'accent' : 'white'"
      to="/contact-us"
      outlined
      target="_blank"
    >
      Contactanos
    </base-btn>-->
  </div>
</template>

<script>
  export default {
    name: 'BaseInteres',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'Sitios de Interés',
      },
      title2: {
        type: String,
        default: 'Patólogos Clínicos Certificados',
      },
    },
  }
</script>
