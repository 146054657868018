<template>
  <div>
    <h3 class="mb-3">¿ Deseas Certificarte por Primera Vez ?</h3>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />
    <v-form ref="formRegistro" v-model="valid" lazy-validation>

      <v-text-field
        outlined
        v-bind="$attrs"
        v-on="$listeners"
        label="Nombre(s)"
        type="text"
        v-model="registro.nombres"
        :rules="[rules.required]"
        dense
      />

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="registro.primer_apellido"
            outlined
            v-bind="$attrs"
            v-on="$listeners"
            label="Primer Apellido"
            dense
            type="text"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="registro.segundo_apellido"
            outlined
            v-bind="$attrs"
            v-on="$listeners"
            label="Segundo Apellido"
            dense
          />

        </v-col>
      </v-row>

      <v-text-field
        v-model="registro.cedula"
        outlined
        v-bind="$attrs"
        v-on="$listeners"
        label="Número de Cédula Licenciatura ( Federal )"
        dense
        type="text"
        :rules="[rules.required]"
      />
      <v-text-field
        v-model="registro.email"
        outlined
        v-bind="$attrs"
        v-on="$listeners"
        label="Correo Electrónico"
        dense
        :rules="[rules.required, rules.email]"
      />
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="registro.password"
            outlined
            v-bind="$attrs"
            v-on="$listeners"
            label="Contraseña"
            dense
            id="password"
            name="password"
            :type="showpass ? 'text' : 'password'"
            :rules="[rules.required, rules.password]"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showpass = !showpass"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="registro.password_confirmation"
            outlined
            v-bind="$attrs"
            v-on="$listeners"
            label="Confirma Contraseña"
            type="password"
            dense
            :type="show2 ? 'text' : 'password'"
            :rules="[rules.required,(this.registro.password === this.registro.password_confirmation) || 'Las contraseñas no coinciden']"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show2 = !show2"
          />

        </v-col>
      </v-row>

      <div class="mb-5" align="right">
        <a :href="path_aviso_privacidad" target="_blank">Consulta nuestra política de privacidad.</a>
      </div>


      <v-checkbox
        label="He leido, entiendo y acepto la política de privacidad"
        color="green"
        value="red darken-3"
        hide-details
        class="mt-n5 mb-4"
        v-model="checkbox1"
        :rules="[rules.checkbox]"
        required
      ></v-checkbox>

      <div align="center" class="mb-6">
        <vue-recaptcha
          ref="recaptcha"
          @verify="onVerify"
          @expired="onExpired"
          :sitekey="sitekey"
          :loadRecaptchaScript="true">
        </vue-recaptcha>
      </div>

      <v-divider></v-divider>
      <p>Asegurate de tener acceso al correo electrónico que estas registrando ya que este mismo se usará como tu usuario para ingreso
         al sistema de COMPAC.
      </p>




      <v-alert
        v-if="validation_errors.length"
        outlined
        type="error"
        prominent
        border="right"
      >
        <div align="left" class="text-uppercase font-weight-bold">
         Error al crear la cuenta
        </div><br>
        <div align="left" class="font-weight-medium" v-for="(error, key) in this.validation_errors" :key="'validation_'+key">
          -{{ error}}.
        </div>
      </v-alert>


    </v-form>
    <base-btn
      :color="!theme.isDark ? 'accent' : 'white'"
      outlined
      @click.native="registrar()" :disabled="!validRegistro" :loading="loading"
    >
      REGISTRAR
    </base-btn>

  </div>
</template>

<script>
  const axios = require('axios').default;
  import VueRecaptcha from 'vue-recaptcha';

  export default {
    name: 'BaseRegistroMedico',
    components: { VueRecaptcha },


    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: String,
      title: {
        type: String,
        default: '! REGISTRATE !',
      },
    },
    data: () => ({
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      path_aviso_privacidad:process.env.VUE_APP_BASE_URL +'/avisoprivacidad/aviso_privacidad_compac.pdf',
      validation_errors:[], //errorores de validacion del backend
      conback:process.env.VUE_APP_BACK_URL,
      checkbox1: false,
      acepta_politica:true,
      valid: true,
      loading: false,
      showpass: false,
      show1: false,
      show2: false,
      validRegistro: true,

      registro:{
        nombres:'',
        primer_apellido:'',
        segundo_apellido:'',
        cedula:'',
        email:'',
        password:'',
        password_confirmation:'',
      },
      rules: {
        required:v => !!v.trim() || 'Campo requerido',
        checkbox:v => !!v || 'Campo requerido',
        curp: v => v.length == 18 || 'Requiere 18 caracteres',
        password: v => v.length >= 5 || 'Requiere mínimo 5 caracteres',
        email: v =>  {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || 'E-mail invalido.'
        },
      },
    }),
    mounted() {

    },

    methods:{
      onVerify: function (response) {
        this.verifytoken = response
      },
      onExpired: function () {
        console.log('Expired')
        this.verifytoken = ''
        this.$refs.recaptcha.reset();
      },
      registrar() {
        this.validation_errors = [];
        var that = this
        if (this.$refs.formRegistro.validate()) {
          if (!this.verifytoken) {
            this.$swal({ icon: 'warning', title: 'Seleccione la casilla No soy un robot' })
            return false;
          }
          this.loading = true
          //alert("hello");
          this.registro.verifytoken = this.verifytoken
          /*this.$store.dispatch("SAVE_USERS", { url:"/user/abogado", data:this.registro } ).then(response => {
            // this.closeDialog('registro');
            this.$swal({
              icon: 'success',
              title: 'Éxito!!!' + '</br>' +' Favor de revisar el correo proporcionado (' + response.data.data.user.email +') para activar la cuenta.',
              confirmButtonText: "Aceptar"
            })
            setTimeout(function () {
              location.reload()
            }, 2000);
          })
            .catch(error => {
              console.log(error);
              if (error.response.data.errors.email[0] == 'The email has already been taken.') {
                this.$swal({
                  icon: 'error',
                  title: 'El correo ' + this.registro.email + ' ya fue registrado en el sistema.',
                  confirmButtonText: "Aceptar"
                })
              }
              this.loading = false
            });*/

          axios.post(this.conback+'/registrar_medico', this.registro)
            .then(function (response) {
              that.$refs.formRegistro.reset()
              console.log(response.data);
              that.loading = false
              that.$swal({
                icon: 'success',
                title: '¡ Cuenta creada !',
                text: 'Se ha enviado un correo electrónico a ' + response.data.data.user.email +  ' con indicaciones para activar tu cuenta.',
                footer: '<div style="color: red; font-weight: bold">Si el correo no aparece en tu buzón de entrada verifica en correo no deseado o spam',
                confirmButtonText: "Aceptar"
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  location.reload();
                }
              })
            })
            .catch(function (error) {
              that.loading = false
              that.$swal({
                icon: 'error',
                title: 'Error al Crear la Cuenta',
                text: 'Hubo error al registrar la cuenta, verifica tus datos',
                confirmButtonText: "Aceptar"
              })
              //console.log("error al guardar el médico",error.response.data)
              that.loading = false;
              //obtenemos los mensajes de error de la validacion del backend que vienen en en un array simple
              if(error.response.data.hasOwnProperty('messages')){
                for(var i=0; i < error.response.data.messages.length; i++){
                  that.validation_errors.push(error.response.data.messages[i])
                }
              }

            });

        }//end validate


      },
    }

  }
</script>
